import { toggleElementsVisibility } from './helpers/toggleElementsVisibility';
import { selectMultiLevelConfig } from './common/selectMultiLevel';
import { preloader } from './helpers/preloader';

window.HMSWhereToBuy = class WhereToBuy {
	constructor() {
    this.product = null;
    this.productName = null;
    this.radius = null;
    this.zipCode = null;
    this.segment = null;
    this.productData = null;
    this.segmentFilter = null;
    this.allProductsHtml = $('#product').html();

    this.addEventListeners();

    if ($('.js-product-segment-btn').length > 0) {
      this.getCgrProductData();
    }
	}

  renderFilteredProducts() {
    const filteredProductData = this.productData.results
      .find(segment => segment.SegmentUrlFriendy === this.segmentFilter);
    let html = '';

    if (filteredProductData) {
      filteredProductData.Families.forEach(family => {
        let optionsHtml = '';

        family.Products.forEach(product => {
          optionsHtml +=
            `<option value="${product.UrlFriendlyName}">${product.DisplayName}</option>`;
        });

        html += `
          <optgroup label="${family.FamilyName}">
            ${optionsHtml}
          </optgroup>
        `;
      });
    }

    $('#product').html(html);
  }

  toggleSegment(event) {
    const $product = $('#product');
    $product.select2('destroy');
    $('.js-product-segment-btn').addClass('btn-hollow');

    const $clickedSegment = $(event.currentTarget);
    const isActivate = this.segmentFilter !== $clickedSegment.data('segment');

    if (isActivate) {
      this.segmentFilter = $clickedSegment.data('segment');
      $clickedSegment.removeClass('btn-hollow');
      this.renderFilteredProducts();
      $product.select2({ minimumResultsForSearch: Infinity });
    } else {
      this.segmentFilter = null;
      $product.html(this.allProductsHtml);
      $product.select2(selectMultiLevelConfig);
    }
  }

  onChangeProductLocation() {
    toggleElementsVisibility(
      [
        '.js-specialty-stores-container', '.js-buy-direct', '.js-buy-in-store', '.js-retail-title',
        '.js-where-to-buy-title', '.js-change-radius-value', '.js-change-product-location'
      ],
      false
    );
    $('.js-specialty-stores').empty();
    $('.js-retail-location').empty();
    toggleElementsVisibility(['.js-buy-in-store-crate'], true);
  }

  /**
   * Gets the content for buy direct popup.
   * Inits it. Opens it if open param set to true.
   * @param {String} product Product name
   * @param {String} segment Segment name
   * @param {Boolean} open Set to true to open the popup when data is received.
   * @param {Boolean|String} endpoint Set to true to open the popup when data is received.
   */
  initBuyDirect(product, segment, open = false, endpoint = false) {
    if (open) {
      preloader($('.js-main-preloader'), true);
    }

    $.ajax({
      url: endpoint || '/BuyDirect',
      data: {
        product,
        segment
      },
      type: 'POST',
      success: response => {
        $('#buy-direct').html(response);
        window.HMSBuyNowInstance.init();
        $('.js-select').select2({ minimumResultsForSearch: Infinity });
        toggleElementsVisibility(['.js-buy-direct'], $.trim(response).length > 0);

        // Open buy direct manually when inited from product card
        if (open) {
          preloader($('.js-main-preloader'), false);
          $.magnificPopup.open({
            items: {
              src: '#buy-direct'
            },
            type: 'inline'
          });
        }
      }
    });
  }

  onChangeRadius(event) {
    const radius = $(event.currentTarget).val();
    this.radius = radius;
    $('#radius').val(radius).trigger('change');
    const $preloader = $('.js-changeRadius-preloader');

    $preloader.addClass('show');

    $.ajax({
      url: '/Iri/GetWhereToBuy',
      type: 'POST',
      data: {
        zipCode: this.zipCode,
        product: this.productName,
        radius: this.radius,
        segment: this.segment
      },
      success: data => {
        $('.js-display-miles').html(this.radius);
        $preloader.removeClass('show');
        if (data.results.IriStores.length === 0) {
          this.initBuyDirect(this.productName, this.segment);
        }

        this.render(data);
        window.HMSBuyInStoreInstance.render(data);
      }
    });
  }

  /**
   * Inits Buy Direct popup for products displayed in product card
   * @param {Event} event - clicked button
   */
  onBuyDirectPopupInit(event) {
    const {
      product, segment, endpoint
    } = $(event.currentTarget).data();

    this.initBuyDirect(product, segment, true, endpoint);
  }

  addEventListeners() {
    $('.js-product-segment-btn').on('click', event => this.toggleSegment(event));

    $('.js-change-product-location').on('click', () => this.onChangeProductLocation());

    $('.js-change-radius').on('select2:select', event => this.onChangeRadius(event));

    $(document).on('click', '.js-buy-direct-popup-init', event => this.onBuyDirectPopupInit(event));
  }

  renderStoreList(elements, containerSelector) {
    const $container = $(containerSelector);
    $container.empty();

    elements.forEach(element => {
      const { Name, Address, Phone } = element;

      $container.append(
        `<div class="article-list-group">
          <div class="article-group-item vspace-xs">
            <h3 class="article-item-title">${Name}</h3>
            <p>${Address}</p>
            <p class="article-item vspace-xs">${Phone}</p>
          </div>
        </div>`);
    });
  }

  renderBuyNowItem(data) {
    const { Name, PackShotImage } = data.results.ProductData;

    $('.js-buy-direct-widget-content').html(
      `<div class="align-text-center">
        <img
          src="${PackShotImage}"
          alt="${Name}"
          class="img-fluid lazyload simple-link-list-image"
          data-srcset="
          ${PackShotImage} 334w,
          ${PackShotImage} 255w
          "
          width="334"
          height="167"
        />
      </div>
      <div class="align-text-center"><p>${Name}</p></div>`
    );
  }

  render(data, allSpecialtyStores = false) {
    const { IriStores, SpecialtyStores } = data.results;

    toggleElementsVisibility(['.js-change-product-location', '.js-where-to-buy-title'], true);

    if (IriStores.length > 0 ) {
      window.HMSBuyInStoreInstance.hideForm();
      toggleElementsVisibility(['.js-buy-in-store', '.js-retail-title'], true);
      this.renderStoreList(IriStores, '.js-retail-location');
      toggleElementsVisibility(['.js-buy-in-store-no-results', '.js-change-radius-value'], false);
    } else {
      toggleElementsVisibility(['.js-buy-in-store-no-results', '.js-change-radius-value'], true);
      this.renderBuyNowItem(data);
    }

    if (SpecialtyStores.length > 0) {
      window.HMSBuyInStoreInstance.hideForm();
      $('.js-specialty-stores-container').removeClass('u-display-none');

      this.renderStoreList(allSpecialtyStores || SpecialtyStores, '.js-specialty-stores');
    } else {
      $('.js-specialty-stores-container').addClass('u-display-none');
    }

    if (IriStores.length > 0 || SpecialtyStores.length > 0) {
      window.HMSBuyInStoreInstance.initMap(data);
    }

    if (IriStores.length === 0 && SpecialtyStores.length === 0) {
      window.HMSBuyInStoreInstance.hideForm();
    }

    $('.js-display-productName').html(this.product);
    $('.js-display-miles').html(this.radius);
    $('.js-display-zip-code').html(this.zipCode);
    $('.js-change-radius').val(this.radius);
  }

  getCgrProductData() {
    $.ajax({
      url: '/Iri/GetProducts',
      type: 'POST',
      data: {
        segment: $('#segment').val()
      },
      success: productData => {
        this.productData = productData;
      },
    });
  }

  init(data) {
    const { Name, Radius, ZipCode, UrlFriendlyName, Segment } = data.results.ProductData;

    this.product = Name;
    this.radius = Radius;
    this.zipCode = ZipCode;
    this.productName = UrlFriendlyName;
    this.segment = Segment;

    this.render(data);
	}
};
